/*
 * @Description:
 * @Author: 琢磨先生
 * @Date: 2023-03-21 15:17:05
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-03-21 15:56:43
 */
import http from "./axios_init";
export default {
  /**
   * 获取所有的权限策略
   * @returns
   */
  get_powers(appCode) {
    return http.get("admin/v1/power?code=" + appCode);
  },

  /**
   *
   * @returns
   */
  get_tree(appCode) {
    return http.get("admin/v1/power/get_tree?code=" + appCode);
  },
};
